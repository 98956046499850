@tailwind base;
@tailwind components;
@tailwind utilities;
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
  

/* Add these styles to manage stacking context */
.navbar {
    position: sticky;
    top: 0;
    z-index: 1000; 
  }
  
  .pagination-container {
    position: relative; 
    z-index: 10; 
  }

  
  

  .footer{
    position: sticky;
    top: 0;
    z-index :1000;
  }

